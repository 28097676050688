const taskStatusOptions = [{
  label: '进行中',
  value: 0
},{
  label: '终止',
  value: 1
}]


//告警状态
const warnStatusOptions = [{
  label: '正常',
  value: 0
},{
  label: '超时',
  value: 1
},{
  label: '终止',
  value: 2
}]

//巡防周期
const periodOptions = [{
  label: '按周',
  tip: '告警时间 周四',
  value: 0
},{
  label: '按月',
  tip: '告警时间 每月20号',
  value: 1
},{
  label: '按季度',
  tip: '告警时间 每季度最后一个月',
  value: 2
},{
  label: '按年',
  tip: '告警时间 每年最后以一个月',
  value: 3
}]


// 0:按周/告警时间 周四 
// 1:按月/告警时间 每月20号 
// 2:按季度/告警时间 每季度最后一个月 
// 3:按年/告警时间 每年最后以一个月



//巡防周期完成状态
const periodStatusOptions = [{
  label: '未完成',
  value: 0
},{
  label: '已完成',
  value: 1
}]


export {
  taskStatusOptions,
  warnStatusOptions,
  periodOptions,
  periodStatusOptions
}
