//处理状态
const problemStatusOptions = [{
  label: '待处理',
  value: 0
},{
  label: '已处理',
  value: 1
}]



export {
  problemStatusOptions
}
