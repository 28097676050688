import request from '@/utils/request'


// 巡防问题列表
export function problemPage(params) {
  return request({
    url: '/patrolTask/problemPage',
    method: 'get',
    params:params
  })
}


// 巡防问题统计
export function problemTotal(params) {
  return request({
    url: '/patrolTask/problem',
    method: 'get',
    params:params
  })
}

//问题处理
export function processProblem(params) {
  return request({
    url: '/patrolTask/processProblem',
    method: 'get',
    params:params
  })
}
//批量处理
export function processProblems(params) {
  return request({
    url: '/patrolTask/processProblems',
    method: 'get',
    params:params
  })
}

//问题详情
export function problemDetail(params) {
  return request({
    url: '/patrolTask/problemDetail',
    method: 'get',
    params:params
  })
}

//周期修改列表
export function changePeriodPage(params) {
  return request({
    url: '/patrolTask/changePeriodPage',
    method: 'get',
    params:params
  })
}
//周期审核
export function changePeriodAudit(params) {
  return request({
    url: '/patrolTask/changePeriodAudit',
    method: 'get',
    params:params
  })
}
//周期审核流水
export function auditLogPage(params) {
  return request({
    url: '/patrolTask/auditLogPage',
    method: 'get',
    params:params
  })
}